<script>
import Card from "../Card.vue";
import QuestionHTML from "../QuestionHTML.vue";

export default {
  components: {
    Card,
    QuestionHTML,
  },

  props: {
    question: {
      type: Object,
      required: true,
    },
  },

  setup(props) {
    return {
      props,
    };
  },
};
</script>

<template>
  <Card>
    <template #header>
      <div class="header">
        <h3>{{ question.title }}</h3>
        <span v-if="question.priority" class="priority">
          Приоритет: {{ question.priority }}
        </span>
      </div>

      <div class="actions">
        <slot name="actions" />
      </div>
    </template>

    <QuestionHTML :body="question.text" />
  </Card>
</template>

<style scoped>
.header {
  @apply flex flex-col;
}

.priority {
  @apply leading-none text-xs text-gray-400;
}

.actions {
  @apply flex gap-2;
}
</style>
