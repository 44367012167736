<script>
export default {
  props: {
    to: { type: Object, required: true },
    exact: Boolean,
  },

  setup() {},
};
</script>

<template>
  <router-link
    :to="to"
    :exact="exact"
    class="tab-item"
    active-class="tab-item--active"
    exact-active-class="tab-item--active"
  >
    <slot />
  </router-link>
</template>

<style scoped>
.tab-item {
  @apply px-3 py-2 text-sm leading-none;
  @apply font-medium text-gray-500 rounded-md;
}
.tab-item:hover {
  @apply bg-gray-100 text-gray-700;
}
.tab-item--active {
  @apply bg-gray-100 text-gray-700;
}

.tab-item:not(:first-child) {
  @apply ml-3;
}
</style>
