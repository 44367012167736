import { ref } from "vue";
import { sortBy } from "lodash";
import * as api from "../api";

export default function useSchools() {
  const schools = ref([]);
  const school = ref(null);
  const isPending = ref(false);

  const fetchSchools = () => {
    isPending.value = true;

    return api
      .fetchSchools()
      .then(
        (response) =>
          (schools.value = sortBy(response.data, ["number", "build"]))
      )
      .finally(() => (isPending.value = false));
  };

  const updateSchoolTags = (schoolId, tags) =>
    api.updateSchoolTags(schoolId, tags);

  const fetchSchool = (schoolId) =>
    api
      .fetchSchool(schoolId)
      .then((response) => (school.value = response.data));

  return {
    schools,
    school,
    isPending,
    fetchSchools,
    updateSchoolTags,
    fetchSchool,
  };
}
