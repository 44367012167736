<script>
import { onMounted, watch } from "vue";
import { useRoute } from "vue-router";
import { ArrowNarrowLeftIcon } from "@heroicons/vue/solid";

import useFaq from "../../composables/useFaq";
import useSchools from "../../composables/useSchools";
import TopBar from "../../components/faq/TopBar.vue";
import Header from "../../components/faq/Header.vue";
import Footer from "../../components/faq/Footer.vue";
import Question from "../../components/Question.vue";

export default {
  components: {
    TopBar,
    Header,
    Footer,
    ArrowNarrowLeftIcon,
    Question,
  },

  setup() {
    const route = useRoute();

    const { questions, fetchFaq } = useFaq();
    const { school, fetchSchool } = useSchools();

    const fetchData = () => {
      fetchFaq(route.query.schoolId);
      fetchSchool(route.query.schoolId);
    };

    onMounted(() => fetchData());

    watch(
      () => route.query,
      () => route.name === "faq" && fetchData()
    );

    return {
      route,
      questions,
      school,
    };
  },
};
</script>

<template>
  <div class="min-h-screen flex flex-col">
    <TopBar />

    <Header />

    <div class="max-w-6xl w-full mx-auto px-4 flex-1">
      <div
        class="
          grid
          lg:grid-cols-12
          gap-16
          mt-8
          sm:mt-10
          md:mt-12
          lg:mt-14
          xl:mt-16
        "
      >
        <nav class="hidden lg:block col-span-4">
          <div class="sticky top-20 z-10">
            <router-link
              :to="{ name: 'home' }"
              class="flex items-center font-medium"
            >
              <ArrowNarrowLeftIcon class="w-5 h-5 mr-4" />
              Назад
            </router-link>

            <ul class="mt-8 pt-8 border-t">
              <p class="text-xl font-bold mb-4">Задаваемые вопросы</p>
              <li
                v-for="question in questions"
                :key="question.id"
                class="text-base text-gray-500 my-2 hover:text-gray-700"
              >
                <a href="#" v-scroll-to="`#question${question.id}`">
                  {{ question.title }}
                </a>
              </li>
            </ul>
          </div>
        </nav>

        <div class="lg:col-span-8">
          <h1
            class="
              mb-1
              sm:mb-2
              md:mb-3
              lg:mb-4
              text-2xl
              sm:text-3xl
              md:text-4xl
              lg:text-5xl
              xl:text-6xl
              font-bold
            "
          >
            Ответы на вопросы
          </h1>
          <h3
            class="
              text-base
              sm:text-lg
              md:text-xl
              lg:text-2xl
              font-medium
              text-gray-500
              mb-8
            "
          >
            {{ school?.place.name }} &ndash; {{ school?.full_name }}
          </h3>

          <Question
            v-for="question in questions"
            :key="question.id"
            :question="question"
          />
        </div>
      </div>
    </div>

    <Footer />
  </div>
</template>
