<script>
import { computed, ref } from "vue";
import { SelectorIcon } from "@heroicons/vue/solid";

export default {
  components: { SelectorIcon },

  props: {
    items: { type: Array, default: () => [] },
    itemName: { type: String, default: "name" },
    itemValue: { type: String, default: "id" },
    modelValue: { type: [Array, Object, String, Number] },
    disabled: Boolean,
    placeholder: String,
  },

  emits: ["update:modelValue"],

  setup(props, { emit }) {
    const show = ref(false);

    const open = () => (show.value = true);
    const close = () => (show.value = false);

    const name = computed(
      () =>
        props.items.find((i) => i[props.itemValue] === props.modelValue)?.[
          props.itemName
        ]
    );

    const selectItem = (item) => {
      emit("update:modelValue", item[props.itemValue]);
      close();
    };

    return {
      props,
      show,
      name,
      open,
      close,
      selectItem,
    };
  },
};
</script>

<template>
  <div
    v-click-outside="close"
    class="relative"
    :class="{ 'pointer-events-none opacity-40': disabled }"
  >
    <div
      @click="open"
      class="
        pl-6
        pr-10
        cursor-pointer
        flex
        items-center
        justify-between
        bg-white
        rounded-lg
        select-none
        text-base
        lg:text-lg
        h-14
        lg:h-16
        shadow-card
      "
    >
      <p class="min-w-0 truncate">
        {{ name || placeholder }}
      </p>

      <SelectorIcon class="absolute right-2 w-5 h-5 text-gray-400" />
    </div>

    <div
      v-if="show"
      class="
        absolute
        z-10
        w-full
        p-2
        mt-1
        overflow-auto
        bg-white
        rounded-md
        shadow-card
        max-h-64
        text-sm
        lg:text-base
      "
    >
      <div
        v-for="(item, index) in items"
        :key="index"
        @click="selectItem(item)"
        class="
          cursor-pointer
          select-none
          rounded-md
          flex
          items-center
          px-6
          h-10
          lg:h-12
          hover:text-purple-500 hover:bg-purple-50
        "
        :class="{
          'font-medium ': item[itemValue] === modelValue,
        }"
      >
        <p class="min-w-0 truncate">{{ item[itemName] }}</p>
      </div>
    </div>
  </div>
</template>
