import { ref } from "vue";
import * as api from "../api";

export default function usePlaces() {
  const place = ref(null);

  const fetchPlace = (id) =>
    api.fetchPlace(id).then((response) => (place.value = response.data));

  return {
    place,
    fetchPlace,
  };
}
