<script>
export default {
  setup() {},
};
</script>

<template>
  <router-link
    tag="div"
    :to="{ name: 'home' }"
    class="inline-flex self-start items-center"
  >
    <img
      src="../../assets/images/pioneer-logo.svg"
      alt="Логотип ПИОНЕР"
      class="h-8 md:h-12"
    />
    <div
      class="
        ml-4
        font-bold
        tracking-wide
        text-gray-700 text-sm
        md:text-base
        leading-none
        md:leading-none
      "
    >
      <p class="my-0.5">Система цифровизации</p>
      <p class="my-0.5">образовательной среды ПИОНЕР</p>
    </div>
  </router-link>
</template>
