import { createRouter, createWebHashHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("../views/faq/Home.vue"),
  },

  {
    path: "/faq",
    name: "faq",
    component: () => import("../views/faq/FAQ.vue"),
  },

  {
    path: "/login",
    name: "login",
    component: () => import("../views/admin/Login.vue"),
  },

  {
    path: "/admin",
    name: "admin",
    component: () => import("../views/admin/Admin.vue"),

    children: [
      {
        path: "",
        name: "qa",
        component: () => import("../views/admin/QA.vue"),
      },

      {
        path: "tags",
        name: "tags",
        component: () => import("../views/admin/Tags.vue"),
      },

      {
        path: "schools",
        name: "schools",
        component: () => import("../views/admin/Schools.vue"),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
