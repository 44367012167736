import { ref } from "vue";
import * as api from "../api";

export default function useRegions() {
  const regions = ref([]);

  const fetchRegions = () =>
    api.fetchRegions().then((response) => (regions.value = response.data));

  return {
    regions,
    fetchRegions,
  };
}
