<script>
export default {
  props: {
    body: String,
  },

  setup(props) {
    return {
      props,
    };
  },
};
</script>

<template>
  <div class="question-body" v-html="body"></div>
</template>

<style>
.ProseMirror h1,
.question-body h1 {
  @apply font-medium text-xl mb-4;
  @apply sm:text-2xl sm:mb-6;
  @apply md:text-3xl sm:mb-8;
}

.ProseMirror h2,
.question-body h2 {
  @apply font-medium text-lg mb-2;
  @apply sm:text-xl sm:mb-4;
  @apply md:text-2xl sm:mb-6;
}

.ProseMirror h3,
.question-body h3 {
  @apply font-medium text-base mb-2;
  @apply sm:text-lg sm:mb-4;
  @apply md:text-xl sm:mb-6;
}

.ProseMirror p,
.question-body p {
  @apply mb-2 text-sm leading-relaxed;
  @apply sm:mb-3 sm:text-base;
  @apply md:mb-4;
  @apply break-words;
}

.ProseMirror p + h1,
.question-body p + h1,
.ProseMirror p + h2,
.question-body p + h2,
.ProseMirror p + h3,
.question-body p + h3 {
  @apply mt-6 sm:mt-8 md:mt-10;
}

.ProseMirror img,
.question-body img {
  @apply w-full mb-2 rounded-md;
  @apply sm:mb-3;
  @apply md:mb-4;
}

.ProseMirror ul,
.question-body ul {
  @apply list-disc ml-4 mb-2 sm:mb-3 md:mb-4;
}

.ProseMirror ul > li,
.question-body ul > li {
  @apply list-item pl-2;
}

.ProseMirror ul p,
.question-body ul p {
  @apply m-0;
}

.ProseMirror ol,
.question-body ol {
  @apply list-decimal ml-4 mb-2 sm:mb-3 md:mb-4;
}

.ProseMirror ol > li,
.question-body ol > li {
  @apply list-item pl-2;
}

.ProseMirror ol > li::marker,
.question-body ol > li::marker {
  @apply text-sm;
}

.ProseMirror ol p,
.question-body ol p {
  @apply m-0;
}

.ProseMirror a,
.question-body a {
  @apply text-blue-500 font-medium cursor-pointer;
}
</style>
