<script>
import { ref, watch } from "vue";
import { XIcon } from "@heroicons/vue/solid";

export default {
  components: { XIcon },

  props: {
    maxWidth: { type: Number, default: 360 },
    modelValue: Boolean,
  },

  emits: ["update:modelValue"],

  setup(props, { emit }) {
    const show = ref(false);

    watch(
      () => props.modelValue,
      () => {
        console.log(props.modelValue);
        props.modelValue ? open() : close();
      }
    );

    const open = () => {
      show.value = true;
      emit("update:modelValue", true);
    };
    const close = () => {
      show.value = false;
      emit("update:modelValue", false);
    };

    return {
      props,
      show,
      open,
      close,
    };
  },
};
</script>

<template>
  <slot name="activator" :open="open" />

  <teleport to="body">
    <div
      v-if="show"
      class="
        fixed
        inset-0
        z-50
        bg-black bg-opacity-40
        flex
        items-center
        justify-center
        p-4
      "
    >
      <div class="relative w-full" :style="{ maxWidth: maxWidth + 'px' }">
        <slot />

        <button
          @click="close"
          class="
            absolute
            flex
            items-center
            justify-center
            w-8
            h-8
            bg-black bg-opacity-40
            text-white
            shadow-lg
            rounded-full
            -right-8
            -top-8
          "
        >
          <XIcon class="w-5 h-5" />
        </button>
      </div>
    </div>
  </teleport>
</template>
