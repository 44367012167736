<script>
export default {
  setup() {},
};
</script>

<template>
  <div
    class="
      sticky
      top-0
      z-50
      bg-gray-100
      overflow-x-auto
      whitespace-nowrap
      relative
    "
  >
    <nav
      class="
        max-w-6xl
        w-full
        mx-auto
        flex
        items-center
        justify-between
        h-10
        px-4
      "
    >
      <div>
        <a
          href="http://z.edu28.ru/pay"
          target="_blank"
          rel="noreferrer"
          class="
            text-xs
            mr-4
            md:mr-8 md:text-sm
            font-medium
            text-gray-400
            hover:text-gray-700
          "
        >
          Оплата услуг
        </a>

        <a
          href="http://z.edu28.ru/notify"
          target="_blank"
          rel="noreferrer"
          class="
            text-xs
            mr-4
            md:mr-8 md:text-sm
            font-medium
            text-gray-400
            hover:text-gray-700
          "
        >
          Уведомления
        </a>
      </div>

      <div>
        <a
          href="http://lk.edu28.ru/"
          target="_blank"
          rel="noreferrer"
          class="
            text-xs
            mrl-4
            md:ml-8 md:text-sm
            font-medium
            text-gray-400
            hover:text-gray-700
          "
        >
          Личный кабинет
        </a>
      </div>
    </nav>
  </div>
</template>
