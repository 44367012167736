<script>
import { computed, onMounted, ref, watch } from "vue";
import { uniqBy, sortBy } from "lodash";
import useSchools from "../../composables/useSchools";
import useTags from "../../composables/useTags";
import { useRoute } from "vue-router";
import School from "../../components/admin/School.vue";
import Card from "../../components/Card.vue";

export default {
  components: { School, Card },

  setup() {
    const route = useRoute();

    const { schools, fetchSchools, updateSchoolTags } = useSchools();
    const { tags, fetchTags } = useTags();

    const selectedTags = ref({});
    const isPendingUpdateTags = ref(false);

    onMounted(() => Promise.all([fetchSchools(), fetchTags()]));

    watch(
      () => schools.value,
      () => {
        if (!schools.value.length || !tags.value.length) return;

        selectedTags.value = schools.value.reduce(
          (acc, school) =>
            Object.assign(acc, {
              [school.id]: school.tags.map((tag) => tag.id),
            }),
          {}
        );
      }
    );

    const filteredSchools = computed(() =>
      schools.value.filter((school) => school.place.id === +route.query.placeId)
    );

    const places = computed(() =>
      sortBy(
        uniqBy(
          schools.value.map((school) => school.place),
          "id"
        ),
        "id"
      )
    );

    const handleChange = (schoolId) => {
      isPendingUpdateTags.value = true;
      updateSchoolTags(schoolId, selectedTags.value[schoolId]).finally(
        () => (isPendingUpdateTags.value = false)
      );
    };

    return {
      route,
      tags,
      selectedTags,
      isPendingUpdateTags,
      schools: filteredSchools,
      places,
      handleChange,
    };
  },
};
</script>

<template>
  <div class="grid grid-cols-12 gap-4">
    <aside class="col-span-3">
      <Card class="sticky top-8 z-50">
        <template #header>
          <h3>Населенный пункт</h3>
        </template>

        <ul>
          <li v-for="place in places" :key="place.id">
            <router-link
              :to="{ query: { placeId: place.id } }"
              class="
                px-4
                py-2
                text-base
                font-medium
                block
                rounded-md
                hover:bg-gray-100
              "
              :class="{
                'bg-gray-200 hover:bg-gray-200':
                  +route.query.placeId === place.id,
              }"
            >
              {{ place.name }}
            </router-link>
          </li>
        </ul>
      </Card>
    </aside>

    <main class="col-span-9">
      <Card>
        <template #header>
          <h3>Школы</h3>
        </template>
      </Card>

      <div class="grid gap-4">
        <div class="grid grid-cols-2 gap-4 mb-8">
          <School v-for="school in schools" :key="school.id" :school="school">
            <template v-if="tags.length" #tags>
              <label
                v-for="tag in tags"
                :key="tag.id"
                class="flex items-center"
              >
                <input
                  v-model="selectedTags[school.id]"
                  :value="tag.id"
                  @change="handleChange(school.id)"
                  type="checkbox"
                  class="h-5 w-5 rounded-md"
                  :disabled="isPendingUpdateTags"
                />
                <div class="ml-4">
                  <p class="text-sm font-medium">{{ tag.name }}</p>
                  <p class="text-xs leading-none text-gray-400">
                    {{ tag.description }}
                  </p>
                </div>
              </label>
            </template>
          </School>
        </div>
      </div>
    </main>
  </div>
</template>
