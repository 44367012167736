<script>
import { ref } from "vue";

export default {
  props: {
    tag: Object,
  },

  emits: ["update"],

  setup(props, { emit }) {
    const name = ref(props.tag?.name || "");
    const description = ref(props.tag?.description || "");

    const handleClick = () =>
      emit("update", {
        ...props.tag,
        name: name.value,
        description: description.value,
      });

    return {
      name,
      description,
      handleClick,
    };
  },
};
</script>

<template>
  <div class="grid gap-4">
    <label>
      <p class="text-xs text-gray-400 mb-1">Название тега</p>
      <input
        type="text"
        v-model="name"
        class="h-10 px-4 rounded-md border w-full bg-white shadow-sm"
      />
    </label>

    <label>
      <p class="text-xs text-gray-400 mb-1">Описание тега</p>
      <input
        type="text"
        v-model="description"
        class="h-10 px-4 rounded-md border w-full bg-white shadow-sm"
      />
    </label>

    <button
      type="button"
      @click="handleClick"
      class="
        w-full
        h-10
        flex
        items-center
        justify-center
        text-sm
        font-medium
        rounded-md
        bg-white
        border
        shadow-sm
      "
    >
      Применить
    </button>
  </div>
</template>
