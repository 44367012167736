import "./assets/index.css";
import { createApp } from "vue";
import VueScrollto from "vue-scrollto";
import router from "./router";
import App from "./App.vue";
import clickOutside from "./directives/clickOutside";

createApp(App)
  .use(router)
  .directive("click-outside", clickOutside)
  .use(VueScrollto)
  .mount("#app");
