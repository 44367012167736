<script>
import { ref } from "vue";
import { useRouter } from "vue-router";
import * as api from "../../api";

export default {
  setup() {
    const login = ref("");
    const pwd = ref("");

    const router = useRouter();

    const handleSubmit = () =>
      api
        .login({ login: login.value, pwd: pwd.value })
        .then(() => router.push({ name: "admin" }));

    return {
      login,
      pwd,
      handleSubmit,
    };
  },
};
</script>

<template>
  <div class="h-screen w-screen flex items-center justify-center">
    <form
      @submit.prevent="handleSubmit"
      class="
        grid
        gap-4
        bg-white
        shadow-sm
        rounded-md
        border
        p-4
        w-full
        max-w-sm
      "
    >
      <h3 class="text-xl font-medium mb-4">Вход в панель управления</h3>
      <label>
        <p class="text-sm text-gray-400 leading-none mb-2">
          Логин, имя пользователя
        </p>
        <input
          type="text"
          v-model.trim="login"
          class="bg-white rounded-md shadow-sm border px-4 w-full h-10"
        />
      </label>

      <label>
        <p class="text-sm text-gray-400 leading-none mb-2">Пароль</p>
        <input
          type="password"
          v-model.trim="pwd"
          class="bg-white rounded-md shadow-sm border px-4 w-full h-10"
        />
      </label>

      <button
        type="submit"
        class="bg-purple-500 text-white rounded-md h-10 text-sm font-medium"
      >
        Войти
      </button>
    </form>
  </div>
</template>
