<script>
import { ref } from "vue";
import {
  PhotographIcon,
  MenuIcon,
  ArrowSmLeftIcon,
  ArrowSmRightIcon,
  LinkIcon,
  TrashIcon,
} from "@heroicons/vue/solid";
import { useEditor, EditorContent } from "@tiptap/vue-3";
import StarterKit from "@tiptap/starter-kit";
import Link from "@tiptap/extension-link";
import ExtensionImage from "@tiptap/extension-image";
import Button from "../Button.vue";

export default {
  components: {
    EditorContent,
    Button,
    LinkIcon,
    TrashIcon,
    PhotographIcon,
    MenuIcon,
    ArrowSmLeftIcon,
    ArrowSmRightIcon,
  },

  props: {
    question: { type: Object },
  },

  emits: ["update"],

  setup(props, { emit }) {
    const title = ref(props.question?.title || "");
    const priority = ref(props.question?.priority || 0);

    const editor = useEditor({
      content: props.question?.text || "",
      extensions: [StarterKit, ExtensionImage, Link],
      editorProps: { attributes: { class: "p-4" } },
    });

    const addImage = () => {
      const url = window.prompt("URL");

      if (url) {
        editor.value.chain().focus().setImage({ src: url }).run();
      }
    };

    const setLink = () => {
      const url = window.prompt("URL");

      editor.value
        .chain()
        .focus()
        .extendMarkRange("link")
        .setLink({ href: url })
        .run();
    };

    const handleUpdate = () =>
      emit("update", {
        ...props.question,
        title: title.value,
        priority: priority.value,
        text: editor.value.getHTML(),
      });

    return {
      title,
      priority,
      editor,
      handleUpdate,
      addImage,
      setLink,
    };
  },
};
</script>

<template>
  <div class="grid grid-cols-3 gap-4">
    <label class="col-span-2">
      <p class="text-xs text-gray-400 mb-1">Название вопроса</p>
      <input
        type="text"
        v-model="title"
        class="h-10 px-4 rounded-md border w-full bg-white shadow-sm"
      />
    </label>

    <label class="col-span-1">
      <p class="text-xs text-gray-400 mb-1">Приоритет</p>
      <input
        type="number"
        v-model="priority"
        class="h-10 px-4 rounded-md border w-full bg-white shadow-sm"
      />
    </label>
  </div>

  <div class="mt-4">
    <p class="text-xs text-gray-400 mb-1">Ответ на вопрос</p>

    <div v-if="editor" class="flex gap-2 mb-2 flex-wrap">
      <Button @click="editor.chain().focus().undo().run()" icon>
        <ArrowSmLeftIcon class="w-5 h-5" />
      </Button>

      <Button @click="editor.chain().focus().redo().run()" icon>
        <ArrowSmRightIcon class="w-5 h-5" />
      </Button>

      <Button
        @click="editor.chain().focus().toggleBold().run()"
        :active="editor.isActive('bold')"
        icon
      >
        B
      </Button>

      <Button
        @click="editor.chain().focus().toggleItalic().run()"
        :active="editor.isActive('italic')"
        icon
      >
        I
      </Button>

      <Button
        @click="editor.chain().focus().toggleStrike().run()"
        :active="editor.isActive('strike')"
        icon
        style="text-decoration: line-through"
      >
        S
      </Button>

      <Button @click="addImage" icon>
        <PhotographIcon class="w-5 h-5" />
      </Button>

      <Button
        @click="editor.chain().focus().setParagraph().run()"
        :class="{ 'is-active': editor.isActive('paragraph') }"
        icon
      >
        P
      </Button>

      <Button @click="setLink" icon :active="editor.isActive('link')">
        <LinkIcon class="w-5 h-5" />
      </Button>

      <Button
        @click="editor.chain().focus().unsetLink().run()"
        v-if="editor.isActive('link')"
        icon
      >
        <TrashIcon class="w-5 h-5" />
      </Button>

      <Button
        @click="editor.chain().focus().toggleHeading({ level: 1 }).run()"
        :active="editor.isActive('heading', { level: 1 })"
        icon
      >
        h1
      </Button>

      <Button
        @click="editor.chain().focus().toggleHeading({ level: 2 }).run()"
        :active="editor.isActive('heading', { level: 2 })"
        icon
      >
        h2
      </Button>

      <Button
        @click="editor.chain().focus().toggleHeading({ level: 3 }).run()"
        :active="editor.isActive('heading', { level: 3 })"
        icon
      >
        h3
      </Button>

      <Button
        @click="editor.chain().focus().toggleBulletList().run()"
        :active="editor.isActive('bulletList')"
        icon
      >
        <MenuIcon class="w-5 h-5" />
      </Button>

      <Button
        @click="editor.chain().focus().toggleOrderedList().run()"
        :active="editor.isActive('orderedList')"
        icon
      >
        1,2
      </Button>
    </div>

    <EditorContent
      class="rounded-md shadow-sm border max-h-96 overflow-y-auto"
      :editor="editor"
    />
  </div>

  <button
    type="button"
    @click="handleUpdate"
    class="
      mt-4
      w-full
      h-10
      flex
      items-center
      justify-center
      text-sm
      font-medium
      rounded-md
      bg-white
      border
      shadow-sm
    "
  >
    Применить
  </button>
</template>
