<script>
import { onMounted } from "vue";
import { useRouter } from "vue-router";
import * as api from "../../api";
import Tabs from "../../components/Tabs.vue";
import TabItem from "../../components/TabItem.vue";

export default {
  components: { Tabs, TabItem },
  setup() {
    const router = useRouter();

    onMounted(() => api.currentUser());

    const logout = () => {
      api.logout().then(() => router.push({ name: "login" }));
    };

    return {
      logout,
    };
  },
};
</script>

<template>
  <div class="flex flex-col min-h-screen">
    <div class="bg-white text-gray-700 shadow-sm z-10">
      <div
        class="
          max-w-6xl
          w-full
          mx-auto
          h-10
          flex
          items-center
          justify-between
          px-4
        "
      >
        <div class="flex gap-4 items-center">
          <img src="../../assets/images/pioneer-logo.svg" class="w-8" />
          <p class="text-sm font-medium">Центр поддержки пользователей</p>
        </div>

        <div class="flex items-center gap-8 text-sm font-medium">
          <router-link :to="{ name: 'home' }">На сайт</router-link>
          <button @click="logout" class="font-medium">Выйти</button>
        </div>
      </div>
    </div>

    <nav class="bg-white shadow-sm">
      <Tabs>
        <TabItem :to="{ name: 'qa' }">Вопросы</TabItem>
        <TabItem :to="{ name: 'tags' }">Теги</TabItem>
        <TabItem :to="{ name: 'schools' }">Школы</TabItem>
      </Tabs>
    </nav>

    <div class="max-w-6xl w-full mx-auto p-4 relative">
      <router-view />
    </div>
  </div>
</template>
