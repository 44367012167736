<script>
import QuestionHTML from "./QuestionHTML.vue";

export default {
  components: { QuestionHTML },

  props: {
    question: { type: Object, required: true },
  },

  setup(props) {
    return {
      props,
    };
  },
};
</script>

<template>
  <div :id="`question${question.id}`" class="question">
    <h2 class="question__title">{{ question.title }}</h2>
    <QuestionHTML :body="question.text" />
  </div>
</template>

<style scoped>
.question {
  @apply mt-8 md:mt-10 lg:mt-12;
  @apply pt-8 md:pt-10 lg:pt-12;
  @apply border-t;
}

.question:last-of-type {
  @apply mb-8 md:mb-10 lg:mb-12;
}

.question__title {
  @apply text-xl font-bold mb-4;
  @apply md:text-2xl md:mb-6;
  @apply lg:text-3xl lg:mb-8;
  @apply relative flex items-center;
}
</style>
