<script>
import { onMounted, ref, watch } from "vue";
import { useRoute } from "vue-router";

import { PlusIcon, PencilIcon, TrashIcon } from "@heroicons/vue/solid";

import useQuestions from "../../composables/useQuestions";
import useTags from "../../composables/useTags";

import Button from "../../components/Button.vue";
import Dialog from "../../components/Dialog.vue";

import Question from "../../components/admin/Question.vue";
import QuestionEditor from "../../components/admin/QuestionEditor.vue";
import Card from "../../components/Card.vue";

export default {
  components: {
    PlusIcon,
    PencilIcon,
    TrashIcon,

    Button,
    Dialog,

    Question,
    QuestionEditor,
    Card,
  },

  setup() {
    const route = useRoute();

    const {
      questions,
      isPending,
      fetchQuestions,
      createQuestion,
      updateQuestion,
      deleteQuestion,
    } = useQuestions();
    const { tags, fetchTags } = useTags();

    const showCreateModal = ref(false);
    const showUpdateModal = ref(false);

    onMounted(() => {
      fetchTags();
      route.query.tagId && fetchQuestions(route.query.tagId);
    });

    watch(
      () => route.query,
      () => route.query.tagId && fetchQuestions(route.query.tagId)
    );

    const handleCreate = (question) => {
      createQuestion(route.query.tagId, question);
      showCreateModal.value = false;
    };
    const handleUpdate = (question) => {
      updateQuestion(route.query.tagId, question);
      showUpdateModal.value = false;
    };
    const handleDelete = (id) => {
      deleteQuestion(route.query.tagId, id);
    };

    return {
      route,

      questions,
      isPending,
      tags,

      showCreateModal,
      showUpdateModal,

      handleCreate,
      handleUpdate,
      handleDelete,
    };
  },
};
</script>

<template>
  <div class="layout">
    <aside class="sidebar">
      <div class="sticky-nav">
        <Card>
          <template #header>
            <h3>Список тегов</h3>
          </template>
          <ul>
            <li v-for="tag in tags" :key="tag.id">
              <router-link
                :to="{ query: { tagId: tag.id } }"
                class="
                  px-4
                  py-2
                  text-base
                  font-medium
                  block
                  rounded-md
                  hover:bg-gray-100
                "
                :class="{
                  'bg-gray-100 hover:bg-gray-100':
                    +route.query.tagId === tag.id,
                }"
              >
                {{ tag.name }}
              </router-link>
            </li>
          </ul>
        </Card>
      </div>
    </aside>

    <main class="col-span-8">
      <Card>
        <template #header>
          <h3>Вопросы</h3>

          <Dialog
            v-if="route.query.tagId"
            v-model="showCreateModal"
            :max-width="640"
          >
            <template #activator="{ open }">
              <Button @click="open">
                <template #left>
                  <PlusIcon class="w-5 h-5" />
                </template>
                Новый вопрос
              </Button>
            </template>

            <div class="bg-white rounded-md p-4 shadow-lg">
              <QuestionEditor @update="handleCreate" />
            </div>
          </Dialog>
        </template>
      </Card>

      <div class="grid gap-4">
        <Question
          v-for="question in questions"
          :key="question.id"
          :question="question"
        >
          <template #actions>
            <Dialog :max-width="640">
              <template #activator="{ open }">
                <Button @click="open" icon>
                  <PencilIcon class="w-5 h-5" />
                </Button>
              </template>

              <div class="bg-white rounded-md shadow-lg p-4">
                <QuestionEditor :question="question" @update="handleUpdate" />
              </div>
            </Dialog>

            <Button @click="handleDelete(question.id)" icon>
              <TrashIcon class="w-5 h-5" />
            </Button>
          </template>
        </Question>
      </div>
    </main>
  </div>
</template>

<style scoped>
/* Layout */

.layout {
  @apply grid grid-cols-12 gap-4;
}

/* Sidebar */

.sidebar {
  @apply col-span-4;
}

/* Sticky Nav */

.sticky-nav {
  @apply sticky top-4 z-50;
}
</style>
