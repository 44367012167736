import { ref } from "vue";
import { sortBy } from "lodash";
import * as api from "../api";

export default function useQuestions() {
  const questions = ref([]);
  const isPending = ref(false);

  const fetchQuestions = (tagId) => {
    isPending.value = true;

    return api
      .fetchQuestions(tagId)
      .then(
        (response) =>
          (questions.value = sortBy(response.data, "priority"))
      )
      .finally(() => (isPending.value = false));
  };

  const createQuestion = (tagId, question) =>
    api.createQuestion(tagId, question).then(() => fetchQuestions(tagId));

  const updateQuestion = (tagId, question) =>
    api.updateQuestion(question).then(() => fetchQuestions(tagId));

  const deleteQuestion = (tagId, id) =>
    api.deleteQuestion(id).then(() => fetchQuestions(tagId));

  return {
    questions,
    isPending,
    fetchQuestions,
    createQuestion,
    updateQuestion,
    deleteQuestion,
  };
}
