<script>
import { onMounted, ref } from "vue";
import { PlusIcon, TrashIcon, PencilIcon } from "@heroicons/vue/solid";
import useTags from "../../composables/useTags";
import Tag from "../../components/admin/Tag.vue";
import Dialog from "../../components/Dialog.vue";
import TagEditor from "../../components/admin/TagEditor.vue";
import Button from "../../components/Button.vue";
import Card from "../../components/Card.vue";

export default {
  components: {
    Tag,
    PlusIcon,
    Dialog,
    TagEditor,
    TrashIcon,
    PencilIcon,
    Button,
    Card,
  },

  setup() {
    const { tags, fetchTags, createTag, updateTag, deleteTag } = useTags();

    const showCreateModal = ref(false);
    const showUpdateModal = ref(false);

    onMounted(() => fetchTags());

    const handleCreate = (tag) => {
      createTag(tag);
      showCreateModal.value = false;
    };

    const handleUpdate = (tag) => {
      updateTag(tag);
      showUpdateModal.value = false;
    };

    const handleDelete = (id) => {
      deleteTag(id);
    };

    return {
      tags,

      showCreateModal,
      showUpdateModal,

      handleCreate,
      handleUpdate,
      handleDelete,
    };
  },
};
</script>

<template>
  <Card>
    <template #header>
      <h3>Теги</h3>

      <Dialog v-model="showCreateModal">
        <template #activator="{ open }">
          <Button @click="open">
            <template #left>
              <PlusIcon class="w-5 h-5" />
            </template>
            Новый тег
          </Button>
        </template>

        <div class="bg-white rounded-md shadow-lg p-4">
          <TagEditor @update="handleCreate" />
        </div>
      </Dialog>
    </template>
  </Card>

  <div class="grid grid-cols-2 gap-4">
    <div v-for="tag in tags" :key="tag.id">
      <Tag :tag="tag" @update="handleUpdate">
        <template #actions>
          <Dialog v-model="showUpdateModal">
            <template #activator="{ open }">
              <Button @click="open" icon>
                <PencilIcon class="w-5 h-5" />
              </Button>
            </template>

            <div class="bg-white rounded-md shadow-lg p-4">
              <TagEditor :tag="tag" @update="handleUpdate" />
            </div>
          </Dialog>

          <Button @click="handleDelete(tag.id)" icon>
            <TrashIcon class="w-5 h-5" />
          </Button>
        </template>
      </Tag>
    </div>
  </div>
</template>
