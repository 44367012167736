import { ref } from "vue";
import { sortBy } from "lodash";
import * as api from "../api";

export default function useFaq() {
  const questions = ref([]);

  const fetchFaq = (schoolId) =>
    api
      .fetchFaq(schoolId)
      .then(
        (response) =>
          (questions.value = sortBy(response.data, ["priority"]))
      );

  return {
    questions,
    fetchFaq,
  };
}
