<script>
import Card from "../Card.vue";
export default {
  components: { Card },
  props: {
    tag: { type: Object, required: true },
  },

  setup(props) {
    return {
      props,
    };
  },
};
</script>

<template>
  <Card>
    <template #header>
      <div class="flex flex-col">
        <p>{{ tag.name }}</p>
        <p v-if="tag.description" class="text-xs text-gray-400">
          {{ tag.description }}
        </p>
      </div>

      <div class="flex items-center gap-2">
        <slot name="actions" />
      </div>
    </template>
  </Card>
</template>
