<script>
import { computed } from "@vue/runtime-core";
export default {
  props: {
    icon: Boolean,
    active: Boolean,
    variant: String,
    disabled: Boolean,
  },

  setup(props) {
    const classes = computed(() => ({
      btn: true,
      "btn--icon": props.icon,
      "btn--active": props.active,
      [`btn--${props.variant}`]: props.variant,
      "btn--disabled": props.disabled,
    }));

    return {
      classes,
    };
  },
};
</script>

<template>
  <button type="button" :class="classes" :disabled="disabled">
    <div v-if="$slots.left" class="btn__left">
      <slot name="left" />
    </div>

    <slot />

    <div v-if="$slots.right" class="btn__right">
      <slot name="right" />
    </div>
  </button>
</template>

<style scoped>
.btn {
  @apply inline-flex items-center justify-center;
  @apply h-10 px-4 bg-white;
  @apply rounded-md shadow-sm border;
  @apply text-sm font-medium text-gray-700;
}

.btn__left {
  margin-right: 1em;
}

.btn__right {
  margin-left: 1em;
}

/* Icon */
.btn--icon {
  @apply p-0;
  min-width: 2.5rem;
  max-width: 2.5rem;
  min-height: 2.5rem;
  max-height: 2.5rem;
}

/* Active */
.btn--active {
  @apply bg-gray-700 text-white border-gray-900;
}

/* CTA */
.btn--cta {
  @apply leading-none shadow-none border-none;
  @apply bg-purple-100 text-purple-500;
  @apply px-4 sm:px-5 md:px-6 lg:px-8;
  @apply h-10 sm:h-12 md:h-14 lg:h-16;
  @apply mb-8 lg:mb-16;
  @apply text-base lg:text-xl;
  @apply hover:bg-purple-500 hover:text-white;
}

/* Disabled */
.btn--disabled {
  @apply pointer-events-none opacity-40;
}
</style>
