<script>
export default {
  props: {
    school: { type: Object, required: true },
  },

  setup(props) {
    return {
      props,
    };
  },
};
</script>

<template>
  <div class="bg-white shadow-card rounded-md">
    <header class="flex items-center justify-between p-4">
      <p class="text-lg font-medium w-full">{{ school.full_name }}</p>

      <div class="flex items-center gap-4">
        <slot name="actions" />
      </div>
    </header>

    <section v-if="$slots.tags" class="p-4 grid gap-4">
      <slot name="tags" />
    </section>
  </div>
</template>
