<script>
export default {
  setup() {},
};
</script>

<template>
  <div class="card">
    <div v-if="$slots.header" class="card__header">
      <slot name="header" />
    </div>

    <div v-if="$slots.default" class="card__body">
      <slot />
    </div>
  </div>
</template>

<style scoped>
.card {
  @apply bg-white rounded-md shadow-card;
}
.card__header {
  @apply flex items-center justify-between p-4 mb-4;
  @apply text-lg font-medium;
}
.card__body {
  @apply p-4;
}

.card__header + .card__body {
  @apply border-t border-opacity-60;
  @apply -mt-4;
}
</style>
