<script>
export default {
  setup() {},
};
</script>

<template>
  <div class="tabs">
    <slot />
  </div>
</template>

<style scoped>
.tabs {
  @apply py-3 mx-auto max-w-6xl px-4;
}
</style>
