import { ref } from "vue";
import * as api from "../api";

export default function useTags() {
  const tags = ref([]);
  const selectedTag = ref(null);

  const fetchTags = () =>
    api.fetchTags().then((response) => {
      tags.value = response.data;
      selectedTag.value = response.data[0].id;
    });

  const createTag = (tag) => api.createTag(tag).then(fetchTags);
  const updateTag = (tag) => api.updateTag(tag).then(fetchTags);
  const deleteTag = (id) => api.deleteTag(id).then(fetchTags);

  return {
    tags,
    selectedTag,
    fetchTags,
    createTag,
    updateTag,
    deleteTag,
  };
}
