<script>
export default {
  setup() {},
};
</script>

<template>
  <footer class="bg-gray-100 border-t py-4 md:py-6 lg:py-8">
    <div class="max-w-6xl w-full mx-auto px-4">
      <div class="grid gap-8 md:gap-16 md:grid-cols-12">
        <div class="col-span-6 lg:col-span-3">
          <a href="https://1101001.ru" target="_blank" rel="noreferrer">
            <img
              class="h-6 lg:h-7"
              src="../../assets/images/mip-logo.svg"
              alt="Логотип МИП"
            />
          </a>
        </div>

        <div class="col-span-6 lg:col-span-3">
          <p class="text-gray-500 font-medium text-xs">
            Центр обслуживания абонентов системы ПИОНЕР: <br />
            ул. 50 лет Октября, 29 <br />
            ТЦ «Небесный», сек. 214
          </p>
        </div>

        <div class="col-span-6 lg:col-span-3">
          <h4 class="text-sm font-medium text-gray-700 leading-none mb-4">
            Поддержка
          </h4>

          <ul>
            <li>
              <a
                class="block text-xs text-gray-500 font-medium mb-2"
                href="tel:+74162380978"
              >
                +7 (4162) 380-978
              </a>
            </li>

            <li class="flex items-center mb-2">
              <img src="@/assets/images/whatsapp.svg" class="w-4 h-4 mr-2" />
              <a
                class="block text-xs text-gray-500 font-medium"
                href="tel:+79145380978"
              >
                +7 (924) 041 88-99
              </a>
            </li>
          </ul>
        </div>

        <div class="col-span-6 lg:col-span-3">
          <h4 class="text-sm font-medium text-gray-700 leading-none mb-4">
            Ссылки
          </h4>

          <ul>
            <li>
              <a
                class="block text-xs text-gray-500 font-medium mb-2"
                href="https://1101001.ru"
                rel="noreferrer"
                target="_blank"
              >
                1101001.ru
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </footer>
</template>
