<script>
import { computed, onMounted, ref, watch } from "vue";
import { ArrowNarrowRightIcon } from "@heroicons/vue/outline";
import { useRouter } from "vue-router";
import useRegions from "../../composables/useRegions";
import usePlaces from "../../composables/usePlaces";
import Button from "../../components/Button.vue";
import Select from "../../components/faq/Select.vue";
import TopBar from "../../components/faq/TopBar.vue";
import Header from "../../components/faq/Header.vue";
import Footer from "../../components/faq/Footer.vue";
import { sortBy } from "lodash";

export default {
  components: {
    Select,
    Button,
    ArrowNarrowRightIcon,
    TopBar,
    Header,
    Footer,
  },

  setup() {
    const router = useRouter();

    const { regions, fetchRegions } = useRegions();
    const { place, fetchPlace } = usePlaces();

    const places = ref([]);

    const selectedRegion = ref(null);
    const selectedPlace = ref(null);
    const selectedSchool = ref(null);

    const schools = computed(() => {
      const schools = (place.value?.schools || []).filter((s) => {
        if (s.name?.match(/тест/i)) {
          return false;
        }

        return true;
      });

      return sortBy(schools, (s) => {
        // eslint-disable-next-line no-unused-vars
        const [number = s.name] = s.name?.match(/\d+/i) || [];
        return Number(number);
      });
    });

    onMounted(() =>
      fetchRegions().then(() => (selectedRegion.value = regions.value[0]?.id))
    );

    watch(
      () => selectedRegion.value,
      () => {
        selectedPlace.value = null;
        selectedSchool.value = null;
      }
    );

    watch(
      () => selectedPlace.value,
      () => {
        selectedPlace.value && fetchPlace(selectedPlace.value);
        selectedSchool.value = null;
      }
    );

    watch(
      () => selectedRegion.value,
      () => {
        places.value = regions.value.find(
          (region) => region.id === selectedRegion.value
        )?.places;
      }
    );

    const goToQuestions = () =>
      router.push({ name: "faq", query: { schoolId: selectedSchool.value } });

    return {
      regions,
      places,
      schools,
      selectedRegion,
      selectedPlace,
      selectedSchool,
      goToQuestions,
    };
  },
};
</script>

<template>
  <div class="flex flex-col min-h-screen">
    <TopBar />

    <Header />

    <main class="flex flex-col justify-center flex-1">
      <div class="max-w-6xl w-full mx-auto px-4">
        <section class="my-4 sm:my-6 md:my-8">
          <h1
            class="
              font-bold
              text-3xl
              sm:text-4xl
              md:text-5xl
              lg:text-6xl
              mb-4
              lg:mb-6
            "
          >
            Центр поддержки пользователей
          </h1>

          <p
            class="
              text-gray-400
              font-light
              text-sm
              md:text-base
              lg:text-lg
              mb-8
              sm:mb-12
              md:mb-16
            "
          >
            Получите ответ на свой вопрос, указав регион, населенный пункт и
            ваше учреждение
          </p>
        </section>

        <!-- HELP SELECTOR -->
        <div
          class="grid grid-cols-1 md:grid-cols-3 gap-6 lg:gap-8 mb-8 lg:mb-16"
        >
          <div class="">
            <p class="font-medium mb-2 text-gray-700 text-sm lg:text-lg">
              Регион
            </p>
            <Select
              v-model="selectedRegion"
              :items="regions"
              :disabled="!selectedRegion"
              placeholder="Выберите регион"
            />
          </div>

          <div class="">
            <p class="font-medium mb-2 text-gray-700 text-sm lg:text-lg">
              Населенный пункт
            </p>
            <Select
              v-model="selectedPlace"
              :items="places"
              :disabled="!selectedRegion || !places.length"
              placeholder="Выберите населенный пункт"
            />
          </div>

          <div class="">
            <p class="font-medium mb-2 text-gray-700 text-sm lg:text-lg">
              Школа
            </p>
            <Select
              v-model="selectedSchool"
              :items="schools"
              :disabled="!selectedPlace || !schools.length"
              itemName="full_name"
              placeholder="Выберите школу"
            />
          </div>
        </div>

        <Button
          variant="cta"
          @click="goToQuestions"
          :disabled="!selectedSchool"
        >
          Получить ответ
          <template #right><ArrowNarrowRightIcon class="w-6 h-6" /></template>
        </Button>
      </div>
    </main>

    <Footer />
  </div>
</template>
