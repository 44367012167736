<script>
import Logo from "./Logo.vue";

export default {
  components: { Logo },

  setup() {},
};
</script>

<template>
  <header class="flex items-center justify-start h-16 md:h-24">
    <div class="max-w-6xl w-full mx-auto px-4">
      <Logo />
    </div>
  </header>
</template>
