import axios from "axios";
import router from "./router";

const api = axios.create({
  baseURL: window.location.origin + "/api/v1",
  timeout: 12000,
});

api.interceptors.response.use(undefined, async (error) => {
  const { status, config } = (error && error.response) || {};
  if (status === 401 && !config.url.endsWith("/login")) {
    await router.push({ name: "login" });
  }

  throw error;
});

// Auth
export function login({ login, pwd }) {
  return api.post(
    `/auth?login=${login}&pwd=${pwd}`,
    {},
    {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    }
  );
}

export function logout() {
  return api.get(`/logout`);
}

export function currentUser() {
  return api.get(`/current-user`);
}

// Schools
export function fetchSchools() {
  return api.get("/faq/school");
}

export function fetchSchool(schoolId) {
  return api.get(`/faq/school/${schoolId}`);
}

// Tags
export function updateSchoolTags(schoolId, tags) {
  return api.put(`/faq/school/${schoolId}/tag`, { tags });
}

// Regions
export function fetchRegions() {
  return api.get("/faq/region");
}

export function fetchRegion(regionId) {
  return api.get(`/faq/regios/${regionId}`);
}

// Place
export function fetchPlace(placeId) {
  return api.get(`/faq/place/${placeId}`);
}

// Tags
export function fetchTags() {
  return api.get("/faq/tag");
}

export function createTag({ name, description }) {
  return api.post(`/faq/tag`, { name, description });
}

export function updateTag(tag) {
  return api.put(`/faq/tag`, tag);
}

export function deleteTag(tagId) {
  return api.delete(`/faq/tag/${tagId}`);
}

// Questions
export function fetchQuestions(tagId) {
  return api.get(`/faq/tag/${tagId}/question`);
}

export function createQuestion(tagId, question) {
  return api.post(`/faq/tag/${tagId}/question`, question);
}

export function updateQuestion(question) {
  return api.put("/faq/question", question);
}

export function deleteQuestion(questionId) {
  return api.delete(`/faq/question/${questionId}`);
}

export function updateQuestionTags(questionId, params) {
  return api.put(`/faq/question/${questionId}/tags`, params);
}

// Faq
export function fetchFaq(schoolId) {
  return api.get(`/faq/school/${schoolId}/question`);
}
